import axios from 'axios'
import { notification } from 'antd';

const urlProvider = `${process.env.REACT_APP_API_URL_V2}/admin/provider`;

export function getProvidersService() {
    return new Promise((resolve) => {
        axios({
            method: 'get',
            url: `${urlProvider}`,
            headers: {
                authorization: JSON.parse(localStorage.getItem('userData')).token,
            },
        })
        .then(response => {
            console.log(response.data.data);
            
            resolve(response.data.data.ProviderAdmin)
        })
        .catch(() => {
            notification.warning({
            message: 'Error',
            description: process.env.REACT_APP_ERROR_API,
            })
        })
    })
}

export async function getTotalProducts() {
	return new Promise((resolve) => {
		axios({
			method: 'get',
			url: `${process.env.REACT_APP_API_URL_V2}/admin/products/totalProducts`,
			headers: {
			authorization: JSON.parse(localStorage.getItem('userData')).token,
			},
		}).then(response => {
			resolve(response.data.data)
		})
	})
}
