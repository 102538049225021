import { all, takeEvery, put } from 'redux-saga/effects'
import actions from './actions'

export function* LOAD_CURRENT_CITY() {

  const dataCity = localStorage.getItem("userCity") ?
    JSON.parse(localStorage.getItem("userCity")) : {};

  if (dataCity) {
    yield put({
      type: 'city/SET_STATE',
      payload: {id: dataCity.id, city: dataCity.city, id_provider: dataCity.id_provider},
    })
  }

}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_CURRENT_CITY, LOAD_CURRENT_CITY),
    LOAD_CURRENT_CITY(),
  ])
}
