/* eslint-disable import/prefer-default-export */
export async function getLeftMenuData() {

  let arrayMenu = []
  const role = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')).role : '';

  switch (role) {

    case "ADMIN":
    case "SUPERADMIN":
      arrayMenu = [
        {
          title: 'Dashboard',
          key: 'Dashboard',
          url: '/admin/dashboardQuqo',
          icon: 'icmn icmn-home',
          role: 'admin',
        },
        {
          title: 'Promociones',
          key: 'Promo',
          icon: 'fa fa-tags',
          url: '/admin/promotions',
          role: 'admin',
          children: [
            {
              title: 'Listado de Promociones',
              key: 'Promo',
              url: '/admin/promotions',
              role: 'admin',
              pro: true,
            },
            {
              key: 'promotionForm',
              title: 'Formulario Promoción',
              url: '/admin/promotionsForm/0',
              pro: true,
            },
          ],
        },
        {
          title: 'Ordenes',
          key: 'Order',
          url: '/admin/orders',
          icon: 'icmn icmn-cart',
          role: 'admin',
          children: [
            {
              title: 'Ordenes',
              key: 'Order',
              url: '/admin/orders',
              role: 'admin',
              pro: true,
            },
            {
              title: 'Items de Ordenes',
              key: 'ItemsdeOrdenes',
              url: '/admin/OrderItems/0/quqo',
              role: 'admin',
              pro: true,
            },
          ],
        },
        {
          title: 'Productos',
          key: 'Productos',
          url: '/admin/products',
          icon: 'icmn icmn-barcode',
          role: 'admin',
        },
        {
          title: 'Actualizar Catalogo',
          key: 'UploadProducts',
          url: '/admin/updateCatalog',
          icon: 'icmn icmn-upload2',
          role: 'admin',
        },
        {
          title: 'Usuarios',
          key: 'Usuarios',
          url: '/admin/Users',
          icon: 'icmn icmn-users',
          role: 'admin',
        },
        {
          title: 'Tracking Comercial',
          key: 'Comerciales',
          url: '/admin/TrackingComercial',
          icon: 'icmn icmn-map',
          role: 'admin',
        },
        {
          title: 'Misiones',
          key: 'Misiones',
          url: '/admin/missionsDashboard',
          icon: 'icmn icmn-flag',
          role: 'admin',
          children: [
            {
              title: 'Misiones',
              key: 'Order',
              url: '/admin/missionsDashboard',
              role: 'admin',
              pro: true,
            },
            {
              title: 'Crear Misión',
              key: 'CrearTeMisión',
              url: '/admin/missionsForm/0',
              role: 'admin',
              pro: true,
            },
          ],
        },
      ]
      break;


    case "SUPERVISOR":
      arrayMenu = [
        {
          title: 'Dashboard',
          key: 'Dashboard',
          url: '/admin/Dashboard',
          icon: 'icmn icmn-home',
          role: 'admin',
        },
        {
          title: 'Promociones',
          key: 'Promo',
          url: '/admin/promocion',
          icon: 'fa fa-tags',
          role: 'admin',
        },
        {
          title: 'Ordenes',
          key: 'Order',
          url: '/admin/Order',
          icon: 'icmn icmn-cart',
          role: 'admin',
        },
        {
          title: 'Productos',
          key: 'Productos',
          url: '/admin/products',
          icon: 'icmn icmn-barcode',
          role: 'admin',
        },
        {
          title: 'Usuarios',
          key: 'Usuarios',
          url: '/admin/Users',
          icon: 'icmn icmn-users',
          role: 'admin',
        },
      ]
      break;

    case "RETAILER":
      arrayMenu = [
        {
          title: 'Ordenes Retailers',
          key: 'Order',
          url: '/admin/ordersRetailers',
          icon: 'icmn icmn-cart',
          role: 'admin',
        },
      ]
      break;

    case "STORAGE":
      arrayMenu = [
        {
          title: 'Ordenes Bodega',
          key: 'Order',
          url: '/admin/ordersRetailers',
          icon: 'icmn icmn-cart',
          role: 'admin',
        },
        {
          title: 'Ordenes Despacho',
          key: 'OrderDespacho',
          url: '/admin/OrdenesDespacho',
          icon: 'icmn icmn-truck',
          role: 'admin',
        },
      ]
      break;

    case "STORE":
      arrayMenu = [
        {
          title: 'Inventario',
          key: 'Promo',
          url: '/admin/Inventario',
          icon: 'icmn icmn-cart',
          role: 'admin',
        },
      ]
      break;

    case "SUPPORT":
      arrayMenu = [
        {
          title: 'Promociones',
          key: 'Promo',
          icon: 'fa fa-tags',
          url: '/admin/promotions',
          role: 'admin',
        },
        {
          title: 'Ordenes',
          key: 'Order',
          url: '/admin/orders',
          icon: 'icmn icmn-cart',
          role: 'admin',
          children: [
            {
              title: 'Ordenes',
              key: 'Order',
              url: '/admin/orders',
              role: 'admin',
              pro: true,
            },
            {
              title: 'Items de Ordenes',
              key: 'ItemsdeOrdenes',
              url: '/admin/OrderItems/0/quqo',
              role: 'admin',
              pro: true,
            },
          ],
        },
        {
          title: 'Usuarios',
          key: 'Usuarios',
          url: '/admin/Users',
          icon: 'icmn icmn-users',
          role: 'admin',
        }
      ]
      break;
      case "MANAGER":
      arrayMenu = [
        {
          title: 'Dashboard',
          key: 'Dashboard',
          url: '/admin/dashboardQuqo',
          icon: 'icmn icmn-home',
          role: 'admin',
        },
        {
          title: 'Promociones',
          key: 'Promo',
          icon: 'fa fa-tags',
          url: '/admin/promotions',
          role: 'admin',
        },
        {
          title: 'Ordenes',
          key: 'Order',
          url: '/admin/orders',
          icon: 'icmn icmn-cart',
          role: 'admin',
          children: [
            {
              title: 'Ordenes',
              key: 'Order',
              url: '/admin/orders',
              role: 'admin',
              pro: true,
            },
            {
              title: 'Items de Ordenes',
              key: 'ItemsdeOrdenes',
              url: '/admin/OrderItems/0/quqo',
              role: 'admin',
              pro: true,
            },
          ],
        },
        {
          title: 'Productos',
          key: 'Productos',
          url: '/admin/products',
          icon: 'icmn icmn-barcode',
          role: 'admin',
        },
        {
          title: 'Usuarios',
          key: 'Usuarios',
          url: '/admin/Users',
          icon: 'icmn icmn-users',
          role: 'admin',
        },
        {
          title: 'Tracking Comercial',
          key: 'Comerciales',
          url: '/admin/TrackingComercial',
          icon: 'icmn icmn-map',
          role: 'admin',
        },
      ]
      break;
    default:
      break
  }
  return arrayMenu;
}
