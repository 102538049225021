import React, { Component } from 'react';
import { Provider } from 'react-redux'
import CacheBuster from './CacheBuster';
import Localization from './components/LayoutComponents/Localization'
import Router from './router'

import { store, history } from './index'
import './global.scss'

class App extends Component {
  render() {
    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
          }

          return (
            <Provider store={store}>
              <Localization>
                <Router history={history} />
              </Localization>
            </Provider>
          );
        }}
      </CacheBuster>
    );
  }
}

export default App;
