/* eslint-disable react/destructuring-assignment */
import React from 'react'
// import { Menu, Dropdown } from 'antd'
import { connect } from 'react-redux'
import styles from './style.module.scss'

@connect(({ settings }) => ({ settings }))
class LanguageSelector extends React.Component {

  state = {
    nameUser: ""
  }
  
  componentDidMount() {
    const nameUser =  JSON.parse(localStorage.getItem('userData')) ? JSON.parse(localStorage.getItem('userData')).name : "No"
    this.setState({
      nameUser
    })
  }

  changeLang = ({ key }) => {
    const { dispatch } = this.props
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'locale',
        value: key,
      },
    })
  }

  render() {
    // const {
    //   settings: { locale },
    // } = this.props
    // const language = locale.substr(0, 2)

    // const langMenu = (
    //   <Menu className={styles.menu} selectedKeys={[locale]} onClick={this.changeLang}>
    //     <Menu.Item key="en-US">
    //       <span role="img" aria-label="English" className="mr-2">
    //         🇬🇧
    //       </span>
    //       English
    //     </Menu.Item>
    //     <Menu.Item key="fr-FR">
    //       <span role="img" aria-label="French" className="mr-2">
    //         🇫🇷
    //       </span>
    //       French
    //     </Menu.Item>
    //     <Menu.Item key="ru-RU">
    //       <span role="img" aria-label="Русский" className="mr-2">
    //         🇷🇺
    //       </span>
    //       Русский
    //     </Menu.Item>
    //     <Menu.Item key="zh-CN">
    //       <span role="img" aria-label="简体中文" className="mr-2">
    //         🇨🇳
    //       </span>
    //       简体中文
    //     </Menu.Item>
    //   </Menu>
    // )
    return (
      // <Dropdown overlay={langMenu} trigger={['click']}>
      //   <div className={styles.dropdown}>
      //     <strong className="text-uppercase">{language}</strong>
      //   </div>
      // </Dropdown>
      <span className={styles.nameUser}>{this.state.nameUser}</span>
    )
  }
}

export default LanguageSelector
