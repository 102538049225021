import React from 'react'
import { Select } from 'antd'
import { connect } from 'react-redux'
import ProfileMenu from './ProfileMenu'
import LanguageSelector from './LanguageSelector'
import styles from './style.module.scss'
import {getProvidersService} from '../../../services/providers'

@connect(({ city, user }) => ({ city, user }))
class TopBar extends React.Component {

  state = {
    cityState: '',
    arrayOptions: []
  }


  componentDidMount() {
    getProvidersService()
    .then(providers => {
      this.setState({
        arrayOptions: providers
      })
    })
    const city = localStorage.getItem('userCity')
      ? JSON.parse(localStorage.getItem('userCity')).city
      : 'BOGOTÁ'
    this.setState({
      cityState: city,
    })
  }

  handleChange = value => {

    const {arrayOptions} = this.state
    const optionSelected = arrayOptions.filter((option) => option.id === Number(value))[0];

    this.setState({
      cityState: optionSelected.city,
    })

    window.localStorage.setItem('userCity', JSON.stringify(optionSelected))
    const { dispatch } = this.props
    dispatch({
      type: 'city/SET_STATE',
      payload: optionSelected,
    })
  }

  render() {
    const { cityState, arrayOptions } = this.state;
    const {user} = this.props

    const CitySelect = () => {
      if (user.role === 'SUPERADMIN' || user.role === 'SUPPORT' || user.role === 'ADMIN') {
        return (
          <Select value={cityState.toUpperCase()} className="width-100p" style={{ width: 200 }} onChange={this.handleChange}>
            {arrayOptions.map((h) =>
              <Select.Option key={h.id}>{h.city.toUpperCase()}</Select.Option>
            )}
          </Select>
        )
      }
      return false
    }

    return (
      <div className={styles.topbar}>
        <div className="mr-auto">

          <CitySelect />

        </div>

        <div className="mr-4">
          <LanguageSelector />
        </div>

        <ProfileMenu />

      </div>
    )
  }
}

export default TopBar
