import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import Loadable from 'react-loadable'

import Loader from 'components/LayoutComponents/Loader'
import IndexLayout from 'layouts'
import NotFoundPage from 'pages/404'

const loadable = loader =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
  })

const routes = [

  // Admin Quqo Pages

  {
    path: '/admin/dashboardQuqo',
    component: loadable(() => import('pages/dashboard/dashboardQuqo')),
  },
  {
    path: '/admin/promotions',
    component: loadable(() => import('pages/dashboard/promotions')),
  },
  {
    path: '/admin/users',
    component: loadable(() => import('pages/dashboard/users')),
  },
  {
    path: '/admin/products',
    component: loadable(() => import('pages/dashboard/products')),
  },
  {
    path: '/admin/promotionsForm/:promoId',
    component: loadable(() => import('pages/dashboard/promotions/promotionForm')),
    exact: true,
  },
  {
    path:'/admin/TrackingComercial',
    component: loadable(() => import('pages/dashboard/trackingComercial')),
  },
  {
    path: '/admin/orders',
    component: loadable(() => import('pages/dashboard/orders/ordersAdmin')),
    exact: true,
  },
  {
    path: '/admin/OrderItems/:idOrder/:provider',
    component: loadable(() => import('pages/dashboard/orders/orderItems')),
    exact: true,
  },
  {
    path: '/admin/updateCatalog',
    component: loadable(() => import('pages/dashboard/updateCatalog')),
    exact: true,
  },

  
  // Retailes Pages
  {
    path: '/admin/ordersRetailers',
    component: loadable(() => import('pages/dashboard/orders/ordersRetailers')),
  },
  {
    path: '/admin/OrdenesDespacho',
    component: loadable(() => import('pages/dashboard/orders/ordersDespacho')),
  },

  // MISIONES
  {
    path: '/admin/missionsDashboard',
    component: loadable(() => import('pages/dashboard/missions/dashboard')),
  },
  {
    path: '/admin/missionsForm/:misionID',
    component: loadable(() => import('pages/dashboard/missions/missionsForm')),
  },



  // Vistas Publicas

  {
    path: '/Activations/:nameCity/',
    component: loadable(() => import('pages/DefaultPages/Activations')),
  },
  {
    path: '/DownloadPdf/:startTime/:endTime/:providerId',
    component: loadable(() => import('pages/DefaultPages/DownloadPdf')),
  },
  {
    path: '/reset/:nameUser/:token',
    component: loadable(() => import('pages/DefaultPages/ResetPassword')),
  },
  
  // System Pages
  {
    path: '/user/login',
    component: loadable(() => import('pages/user/login')),
    exact: true,
  }
]


class Router extends React.Component {
  render() {
    const { history } = this.props
    return (
      <ConnectedRouter history={history}>
        <IndexLayout>
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/admin/dashboardQuqo" />} />
            {routes.map(route => (
              <Route
                path={route.path}
                component={route.component}
                key={route.path}
                exact={route.exact}
              />
            ))}
            <Route component={NotFoundPage} />
          </Switch>
        </IndexLayout>
      </ConnectedRouter>
    )
  }
}

export default Router
