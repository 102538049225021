import React from 'react'
import ReactDOM from 'react-dom'

import { createHashHistory } from 'history'
import { routerMiddleware } from "connected-react-router"
import thunk from 'redux-thunk'
import { logger } from 'redux-logger'
import { applyMiddleware, compose, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import reducers from './redux/reducers'
import sagas from './redux/sagas'
import * as serviceWorker from './serviceWorker'
import App from './App'

const history = createHashHistory()
const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)
const middlewares = [thunk, sagaMiddleware, routeMiddleware]
if (process.env.NODE_ENV === 'development' && true) {
  middlewares.push(logger)
}
const store = createStore(reducers(history), compose(applyMiddleware(...middlewares)))
sagaMiddleware.run(sagas)

ReactDOM.render(
  <App />,
  document.getElementById('root'),
)

serviceWorker.register()

export { store, history }
