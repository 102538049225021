import actions from './actions'

const initialState = {
  id: '1', // ID
  city: 'BOGOTÁ', // BOGOTA
  id_provider: '8', // Provider
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
