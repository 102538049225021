import axios from 'axios'

import { notification } from 'antd';

const urlUsersV2 = `${process.env.REACT_APP_API_URL_V2}/admin`;


export async function login(email, password) {

  const objetoJSON = {
    'credential': email,
    'password': password,
    'ios_token': 132323,
    'android_token': 'fafa'
  }

  return axios
    .post(`${process.env.REACT_APP_API_URL}/auth/login`, objetoJSON)
    .then(response => {

      const payload =  {
        id: response.data.data.user.id,
        name: response.data.data.user.name,
        email: response.data.data.user.email,
        avatar: response.data.data.user.profile_pic,
        role: response.data.data.user.role,
        token: `Bearer ${response.data.data.token}`,
        authorized: true,
      }
      const userData = JSON.stringify(payload);
      localStorage.setItem("userData", userData)

      if (response.data.data.user.role === 'RETAILER' || response.data.data.user.role === 'STORAGE') {
        window.localStorage.setItem('userRetailer', JSON.stringify(response.data.data.user.retailer))
      }

      const dataCity = {
        id: 1,
        city: "BOGOTÁ",
        id_provider: 8
      };

      switch (response.data.data.user.provider.id) {
        case 8:
          break;
        case 9:
          dataCity.id = 2;
          dataCity.city = "MEDELLIN";
          dataCity.id_provider = 9;
          break;
        default:
          break
      }

      localStorage.setItem("userCity", JSON.stringify(dataCity))

      return true;

    })
    .catch((error) => {

      notification.warning({
        message: 'Error',
        description: error.response.data.data.message,
      })

    })

}

export async function currentAccount() {

  function getCurrentUser() {
    return new Promise((resolve) => {
      const dataLocalUser = localStorage.getItem("userData") ?  JSON.parse(localStorage.getItem("userData")): {};
      dataLocalUser.loading = false;
      if (dataLocalUser) {
        resolve(dataLocalUser)
      } else {
        resolve(null)
      }

    })
  }
  return getCurrentUser()
}

export async function logout() {
  localStorage.clear()
}

export async function getAllUsers(citySelected, Offset, limit, field, value) {

  const url = `${urlUsersV2}/getAll/${citySelected}?limit=${limit}&Offset=${Offset}&field=${field}&text=${value}`
  return axios({
    method: 'GET',
    url,
    headers: {
      authorization: JSON.parse(localStorage.getItem('userData')).token,
    },
  }).then(response => {
    const dataResponseService = response.data.data.Users
    return dataResponseService;
  })

}

export async function getAllUsersByRole(role) {

  const url = `${urlUsersV2}/getAllByRole/${role}`

  return axios({
    method: 'GET',
    url,
    headers: {
      authorization: JSON.parse(localStorage.getItem('userData')).token,
    },
  }).then(response => {

    return response.data.data.Users;

  })

}

export async function getTrackingByUserAndDate(date, idUser) {

  const url = `${urlUsersV2}/getTrackingByDate/${date}/${idUser}`

  return axios({
    method: 'GET',
    url,
    headers: {
      authorization: JSON.parse(localStorage.getItem('userData')).token,
    },
  }).then(response => {

    return response.data.data.Tracking;

  })

}

export async function editUser(dataUser) {

  const url = `${urlUsersV2}/editUser`

  return axios({
    method: 'PUT',
    url,
    data: dataUser,
    headers: {
      authorization: JSON.parse(localStorage.getItem('userData')).token,
    },
  })
    .then(() => {
      notification.open({
        type: 'success',
        message: 'Exito ',
        description: 'Se ha Actualizado el usuario.',
      })
    })
    .catch(() => {

      notification.open({
        type: 'Error',
        message: 'Error ',
        description: 'Hubo un error al intentar Cambiar los datos.',
      })

    })

}

export async function changeRole(data, role) {

  const url = `${urlUsersV2}/changeRol/${data.id}/${role}`;

  return axios({
    method: 'GET',
    url,
    headers: {
      authorization: JSON.parse(localStorage.getItem('userData')).token,
    },
  })
    .then(() => {
      notification.open({
        type: 'success',
        message: 'Exito ',
        description: 'Se cambio de rol del usuario exitosamente.',
      })
    })
    .catch(() => {
      notification.open({
        type: 'error',
        message: 'error',
        description: 'Hubo un error al actualizar el rol del usurio, intente nuevamente.',
      })
    })

}

export async function changeReferralCodeService(data, valueInput) {

  const url = `${urlUsersV2}/user/changeReferralCode`;
  const dataRequest = {
    user_id: data.id,
    referral:valueInput
  }
  return axios({
    method: 'PUT',
    url,
    headers: {
      authorization: JSON.parse(localStorage.getItem('userData')).token,
    },
    data: dataRequest
  })
    .then(response => {
      notification.open({
        type: 'success',
        message: 'Exito ',
        description: 'Se cambio el codigo de referido al usuario exitosamente.',
      })
      return response
    })
    .catch(() => {
      notification.open({
        type: 'error',
        message: 'error',
        description:
          'Hubo un error al actualizar el codigo de referido del usurio, intente nuevamente.',
      })
    })
}

export async function changeReferralUserService(data, valueInput) {

  const dataRequest = {
    user_id: data.id,
    referral:valueInput
  }

  const url = `${urlUsersV2}/user/changeReferralUser`;

  return axios({
    method: 'PUT',
    url,
    headers: {
      authorization: JSON.parse(localStorage.getItem('userData')).token,
    },
    data: dataRequest
  })
    .then(response => {
      notification.open({
        type: 'success',
        message: 'Exito ',
        description: 'Se cambio el codigo de referido al usuario exitosamente.',
      })
      return response
    })
    .catch(() => {
      notification.open({
        type: 'error',
        message: 'error',
        description:
          'Hubo un error al actualizar el codigo de referido del usurio, intente nuevamente.',
      })
    })

}

export async function changeStatusUser(idUser, status) {
  
  const url = `${urlUsersV2}/changeUserStatus/${idUser}/${status}`;
  return axios({
    method: 'GET',
    url,
    headers: {
      authorization: JSON.parse(localStorage.getItem('userData')).token,
    },
  })
    .then(() => {
      notification.open({
        type: 'success',
        message: 'Exito ',
        description: 'Se cambio de estado a el usuario exitosamente.',
      })
    })
    .catch(() => {
      notification.open({
        type: 'error',
        message: 'error',
        description:
          'Hubo un error al actualizar el estado del usurio, intente nuevamente luego de recargar la página.',
      })
    })

}

export function sendToServerPasswordService (data) {
  return new Promise((resolve) => {
    axios({
      method: 'POST', 
      url: `${process.env.REACT_APP_API_URL_V2}/auth/passwordReset`,
      config: { headers: { 'Content-Type': 'application/json' } },
      data,
    }).then(reponse => {
			resolve(reponse.data.data)
		})
		.catch(() => {
			notification.warning({
				message: 'Error',
				description: process.env.REACT_APP_ERROR_API,
			})
		})
  })
}

export function ResetPasswordService (data) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL_V2}/auth/sendEmailPasswordReset`,
      config: { headers: { 'Content-Type': 'application/json' } },
      data,
    }).then(() => {
      notification.warning({
        message: 'success',
        description: "Se ha enviado el correo con exito.",
      })
			resolve()
		})
		.catch(() => {
			notification.warning({
				message: 'Error',
				description: process.env.REACT_APP_ERROR_API,
      })
      reject()
		})
  })
}

