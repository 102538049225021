import React from 'react'
import styles from './style.module.scss'


const Footer = () => (
  <div className={styles.footer}>
    <div className={styles.inner}>
      <div className={styles.bottom}>
        <div className="row">
          <div className="col-6">
            <p>
              <strong>Administrador Quqo v{global.appVersion}</strong>
            </p>
            <p>
              Compra y Vende todos tus productos en un solo lugar
            </p>
          </div>
          <div className="col-6">
            <div className={styles.copyright}>
              <img
                src="resources/quqoImages/logoQuqoMenu-mobile.png"
                rel="quqo admin"
                alt="quqo admin"
              />
              <span>
                © 2019{' '}
                <a href="http://mediatec.org/" target="_blank" rel="noopener noreferrer">
                  Quqo
                </a>
                <br />
                Administrador
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Footer
