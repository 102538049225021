import React from 'react'
import { connect } from 'react-redux'
import { Menu, Dropdown, Avatar } from 'antd'
import { FormattedMessage } from 'react-intl'
import styles from './style.module.scss'

@connect(({ user }) => ({ user }))
@connect(({ city }) => ({ city }))
class ProfileMenu extends React.Component {

  logout = () => {
    const { dispatch } = this.props
    dispatch({
      type: 'user/LOGOUT',
    })
  }

  render() {
    const { user, city } = this.props

    const CityLabel = () => {

      if (user.role !== 'SUPERADMIN') {
        return (
          <div>
            <strong>
              <FormattedMessage id="topBar.profileMenu.city" />:{' '}
            </strong>
            {city.city}
            <br />
          </div>
        )
      }
      return false;

    }

    const menu = (
      <Menu selectable={false}>
        <Menu.Item>
          <strong>
            <FormattedMessage id="topBar.profileMenu.hello" />, {user.name || 'Anonymous'}
          </strong>
          <div>
            <strong>
              <FormattedMessage id="topBar.profileMenu.role" />:{' '}
            </strong>
            {user.role}
          </div>

          <CityLabel />

        </Menu.Item>
        <Menu.Item>
          <div>
            <strong>
              <FormattedMessage id="topBar.profileMenu.email" />:{' '}
            </strong>
            {user.email}
            <br />
          </div>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Divider />
        <Menu.Item>
          <a href="javascript: void(0);" onClick={this.logout}>
            <i className={`${styles.menuIcon} icmn-exit`} />
            <FormattedMessage id="topBar.profileMenu.logout" />
          </a>
        </Menu.Item>
      </Menu>
    )
    return (
      <Dropdown overlay={menu} trigger={['click']}>
        <div className={styles.dropdown}>
          <Avatar className={styles.avatar} size={40} style={{backgroundColor: "white"}} src="resources/quqoImages/userAvatar.svg" />
        </div>
      </Dropdown>
    )
  }
}

export default ProfileMenu
