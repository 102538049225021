import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { login, currentAccount, logout } from 'services/user'
import { store as reduxStore } from 'index'
import { Service } from 'axios-middleware'
import axios from 'axios'
import actions from './actions'

const service = new Service(axios);

// MIDDLEWARE TO AXIOS IN RESPONSE
service.register({

  onResponseError(err) {

    if (err.response.status === 403) {

      reduxStore.dispatch({
        type: 'user/LOGOUT',
      })

    }

    throw err;
  }

});

export function* LOGIN({ payload }) {

  const { email, password } = payload;

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(login, email, password)
  if (success) {

      notification.success({
        message: 'Bienvenido',
        description: 'Bienvenido a Quqo Admin',
      })
      yield put({
        type: 'user/LOAD_CURRENT_ACCOUNT',
      })
      yield put({
        type: 'city/LOAD_CURRENT_CITY',
      })

  }

}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(currentAccount)
  if (response) {
    yield put({
      type: 'user/SET_STATE',
      payload: response,
    })
    yield put({
      type: 'menu/GET_DATA',
    })
  }

}

export function* LOGOUT() {
  yield call(logout)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      name: '',
      role: '',
      email: '',
      avatar: '',
      authorized: false,
      loading: false,
    },
  })

}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    LOAD_CURRENT_ACCOUNT(),
  ])
}
